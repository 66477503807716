import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChangeDetectorRef, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { DceListComponent } from './main/components/dce-list/dce-list.component';
import { MapViewComponent } from './main/components/map-view/map-view.component';
import { SideNavComponent } from './main/components/side-nav/side-nav.component';
import { MainPageComponent } from './main/pages';
import { MapPageComponent } from './main/pages/map-page/map-page.component';
import { HeaderNavComponent, FooterComponent } from './shared/components';
import { SharedHomePageComponent, NotFoundComponent, UnauthenticatedComponent, SubscriptionInsufficientComponent } from './shared/pages';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { DceService } from './main/services/dce/dce.service';
import { ExplorerService } from './main/services/explorer.service';
import { ApiService } from './shared/services';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    SideNavComponent,
    MapViewComponent,
    MapPageComponent,
    DceListComponent,
    SharedHomePageComponent,
    HeaderNavComponent,
    FooterComponent,
    NotFoundComponent,
    UnauthenticatedComponent,
    SubscriptionInsufficientComponent,
    HeaderNavComponent,
    SharedHomePageComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    DropdownModule,
    FormsModule,
    CalendarModule,
    PanelModule,
    TableModule,
    AppRoutingModule,
    // SharedModule.forChild(),
    // PrimeNgModule.forChild(),
    LeafletModule,
    LeafletMarkerClusterModule,
  ],  
   bootstrap: [AppComponent],
   providers: [ApiService,ExplorerService, DceService ]
})
export class AppModule { }
