import { Component, OnInit, OnDestroy, OnChanges, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DceService} from 'src/app/main/services/dce/dce.service';
import { Map, tileLayer, latLng, TileLayer } from 'leaflet';
import { environment } from 'src/environments/environment';
import { FeatureMapService } from 'src/app/shared/services/feature-map/feature-map.service';
declare const L: any;
import 'leaflet-draw';
import 'leaflet.markercluster';
import 'leaflet-defaulticon-compatibility';
import { forkJoin } from 'rxjs';
import { DetailedLayersSelector, DetailedLayer } from 'src/app/shared/models/detailed-layers-control/detailed-layers-control'
import * as esri from 'esri-leaflet';
import { FeatureIdentifyControl } from 'src/app/shared/models/feature-identify-control/feature-identify-control'
(window as any).type = false; 
@Component({
  selector: 'me-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],  
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dceList;
  @Input() allDceList;
  @Input() doNotRefreshMap;
  map: Map;
  currentBox: any;
  currentMarker: DataMarker;

  markerClusterData = [];
  polygons = {};
  
  options = {
    layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' }),
    ],
    zoom: 5,
    center: latLng(39.879966, -121.726909),
    preferCanvas: true,
  };

  

  drawControl: any;
  drawnLayerFeatureGroup: L.FeatureGroup = new L.FeatureGroup();
  drawOptions = {
    position: 'topleft',
    draw: {
      polyline: false,
      circle: false,
      polygon: false,
      marker: false,
      circlemarker: false,
    },
    edit: {
      featureGroup: this.drawnLayerFeatureGroup
    } 
  };

  constructor(private cdr: ChangeDetectorRef, private featureNameService: FeatureMapService, private dceService: DceService,) {  }

  onMapReady(map: Map) {
    // Do stuff with map
    this.map = map;

    this.drawControl =  new L.Control.Draw(this.drawOptions).addTo(
      this.map
    );
  
    this.drawnLayerFeatureGroup.addTo(this.map)
    this.map.on('draw:created',  (e : any) => {
      var layer = e.layer;
      this.drawnLayerFeatureGroup.addLayer(layer);
    });

    var baseLayers = {
      'Open Street Map': tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' }),
      'ESRI World Imagery': tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
      }),
      'Open Topo': tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
      }),
    };

    var geoserverUrl = environment.wmsMapServerUrl;
    var groupedOverlays = {
      "Infrastructure": {
        "Counties": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "Counties",
          hoverInfo: "County boundaries are from 2001 and are sourced from nationalatlas.gov. Boundaries were clipped to include only counties in the 8 western states of WA, OR, CA, NV, ID, UT, MY, and WY." 
        }),
        "States": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "WesternStates",
          hoverInfo: "State boundaries are from 2012 and were sourced from nationalatlas.gov. Boundaries were clipped to include only the 8 western states of WA, OR, CA, NV, ID, UT, MY, and WY." 
        }),
        "Land Ownership": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "LandOwnership",
          hoverInfo: "The LND_SurfaceEstate data is a geodatabase illustrating the location of Federal and State managed lands in California and portions northwest Nevada. " 
          + "The data is developed and maintained at the BLM California State Office in Sacramento. The focus of the data development efforts are on accurately depicting the " 
          + "locations of BLM managed lands. Private and non-governmental lands are categorized as unclassified. Other Federal agencies, the State of California , and numerous " 
          + "County governments contribute much of the information and data incorporated into this geodatabase. Data for lands in Nevada were obtained from the Bureau of Land " 
          + "Management, Reno, Nevada.",
          getWMSLegend: true, 
        }),
        "USGS Stream Gauges": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "USGSStreamGauges",
          metadataUrl: `https://water.usgs.gov/GIS/metadata/usgswrd/XML/realstx.xml`,
        })
      },
      "Aquatic": {
        "NHD+ 100K": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "NHD100k",
          metadataUrl: "https://www.epa.gov/waterdata/get-nhdplus-national-hydrography-dataset-plus-data",
        }),
        "HUC 12 - 6th field": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "HUC6",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/4#metadata`,
        }),
        "HUC 10 - 5th field": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "HUC5",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/3#metadata`,
        }),
        "HUC 8 - 4th field": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "HUC4",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/2#metadata`,
        }),
        "Intensively Monitored Watersheds": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "IMW",
          hoverInfo: "The Intensively Monitored Watershed (IMW) layer illustrates the boundaries of IMWs as compiled by the project leaders in the Pacific Northwest. An IMW is an experiment in one or more catchments with a well-developed, long-term monitoring program to determine watershed-scale fish and habitat responses to restoration actions (e.g., Zimmerman et al. 2012). This layer is not published, to find more IMW information go to PNAMP.org/IMW/overview",
        }),
      },
      "Chinook": {
        "Population": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "POPChinook",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/7#metadata`,
        }),
        "Major Population Group": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "MPGChinook",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/6#metadata`,
        }),
        "Evolutionarily Significant Unit": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "ESUChinook",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/5#metadata`,
        })
      },
      "Coho": {
        "Population": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "POPCoho",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/7#metadata`,
        }),
        "Major Population Group": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "MPGCoho",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/6#metadata`,
        }),
        "Evolutionarily Significant Unit": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "ESUCoho",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/5#metadata`,
        })
      },
      "Chum": {
        "Population": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "POPChum",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/7#metadata`,
        }),
        "Major Population Group": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "MPGChum",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/6#metadata`,
          hoverInfo: "Visible at all scales.",
        }),
        "Evolutionarily Significant Unit": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "ESUChum",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/5#metadata`,
        })
      },
      "Steelhead": {
        "Population": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "POPSteelhead",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/7#metadata`,
        }),
        "Major Population Group": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "MPGSteelhead",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/6#metadata`,
        }),
        "Evolutionarily Significant Unit": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "ESUSteelhead",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/5#metadata`,
        })
      },
      "Sockeye": {
        "Population": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "POPSockeye",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/7#metadata`,
          hoverInfo: "Visible at all scales.",
        }),
        "Major Population Group": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "MPGSockeye",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/6#metadata`,
          hoverInfo: "Visible at all scales.",
        }),
        "Evolutionarily Significant Unit": new DetailedLayer(geoserverUrl, {
          format: 'image/png',
          transparent: true,
          // opacity: 0.5,
          layers: "ESUSockeye",
          metadataUrl: `https://gis.sitkatech.com/Dataset/Detail/5#metadata`,
          hoverInfo: "Visible at all scales.",
        })
      },
    };

    let layerControl = new DetailedLayersSelector(baseLayers, groupedOverlays, { position: 'topright', collapsed: false });
    layerControl.addTo(map);

    
    map.on('layerremove', function(event){
      let layerName = (event["layer"].options as any).layers; // todo: figure out how to get the layer name and remove the ( as any)
      let polygonKeys = Object.keys(this.polygons);
      let polygonsToRemove = [];
      
      if (polygonKeys.length == 1)
        this.currentBox.remove();

      for(let key of polygonKeys){
        if (key.indexOf(layerName) > -1){
          polygonsToRemove.push(this.polygons[key]);
          delete this.polygons[key];

          if (this.currentBox){
            let infoBoxToDelete = this.currentBox._container.getElementsByClassName(key)[0];
            this.currentBox._container.removeChild(infoBoxToDelete);
          }
        }
      }

      this.clearPolygonsFromMap(polygonsToRemove);
    }, this);
    map.on("click", this.identifyFeature(map));
  }

  ngOnInit() {
    this.processMarkers();
  }
    
  ngOnChanges() {
    if(!this.doNotRefreshMap){
      this.processMarkers();
    }
  }

  ngOnDestroy() {
    
  }

  private processMarkers() {
      if (this.currentBox) this.currentBox.remove();
      this.markerClusterData = [];
      this.buildMarkersFromLight(this.dceList, this.allDceList, this.dceService);
  }

 

  private buildMarkersFromLight(dceList, allDceList, dceService: DceService) {
    var markers = L.markerClusterGroup();
    dceList.forEach((dce: any) => {
      let mkr = new DataMarker(latLng(dce.Latitude, dce.Longitude), dce);
      mkr.on("click", (dceMarker) => {
        this.showIdentifyBox(dceMarker, allDceList, dceService);
      });
      markers.addLayer(mkr);

    });
    this.markerClusterData.push(markers);
  }

  private showIdentifyBox(dceMarker: DataMarker, allDceList, dceService: DceService) {
    // clean up stuff related to previously selected marker
    if (this.currentBox) this.currentBox.remove();
    if (this.currentMarker) this.currentMarker.target.setOpacity(0.5);
    if (this.polygons) this.clearPolygonsFromMap(this.polygons);

    // update for currently selected marker
    dceMarker.target.setOpacity(1.0);
    this.currentMarker = dceMarker;
    this.currentBox = new TemplatedControl("topleft", "identify-box-template", dceMarker, allDceList, dceService);
    this.currentBox.addTo(this.map);

  }

  private getIdentifyFeatureQueryString(point, size, bbox, layerName, latlng){
    var params = {
      service: 'WMS',
      request: 'GetFeatureInfo',
      version: '1.3.0',  
      crs: 'EPSG:4326',
      styles: 'generic',    
      bbox: `${bbox._southWest.lat},${bbox._southWest.lng},${bbox._northEast.lat},${bbox._northEast.lng}`,
      height: size.y,
      width: size.x,
      layers: layerName, 
      query_layers: layerName, 
      info_format: 'application/json'
    };
    // EF 2/27/20 For some reason the click event gives a y value that a ways south of what it should be, so we use this ugly formula to calculate it from the latlng
    params['i'] = Math.round(size.x*Math.abs(latlng.lng - bbox._southWest.lng) / Math.abs(bbox._northEast.lng - bbox._southWest.lng));
    params['j'] = Math.round(size.y*Math.abs(latlng.lat - bbox._northEast.lat) / Math.abs(bbox._northEast.lat - bbox._southWest.lat));

    return L.Util.getParamString(params, environment.wmsMapServerUrl, true);
  }

  private clearPolygonsFromMap(polygons){
    let polygonIds = Object.keys(polygons);
    for (let polygonId of polygonIds){
      polygons[polygonId].remove();
    }
    
    polygons = {};
  }

  private identifyFeature(map){
    var mapComponent = this;

    return function(e) {
      mapComponent.clearPolygonsFromMap(mapComponent.polygons);
      mapComponent.polygons = {};
      let layers = map._layers
      let layerIds = Object.keys(layers);
      let layerList = [];
      for(let layerId of layerIds) {
        let layer = layers[layerId];
        if (layer.wmsParams){
          layerList.push(`MonitoringExplorer:${layer.wmsParams.layers}`);
        }
      }

      let requests = [],
          count = 0,
          limit = 2;

      for (let layer of layerList) {
        count++;
        if (count > limit)
          break;
        var paramString = mapComponent.getIdentifyFeatureQueryString(e.containerPoint, e.sourceTarget.getSize(), e.sourceTarget.getBounds(), layer, e.latlng);
        var request = mapComponent.featureNameService.getFromFeatureWithParams(paramString);
        requests.push(request);
      }

      forkJoin(requests).subscribe((results : any) => {
        let features = [];
        for (let result of results){
          if (result.features.length > 0){
            features = features.concat(result.features);
          }
        }
        if (features.length > 0){
          // clean up stuff related to previously selected marker
          if (mapComponent.currentBox) mapComponent.currentBox.remove();
          
          mapComponent.currentBox = new FeatureIdentifyControl("topleft", "feature-identify-template",  features);
          mapComponent.currentBox.addTo(map);

          for (let feature of features) {
            if (mapComponent.polygons[feature.id])
              continue;
            let polygonOptions = { color: 'red',
              fillColor: '#f03',
              fillOpacity: 0.5
            };
            //  EF 2/25/20: I can't believe this is performant. Unfortunately, WMS insists on returning lonLats, while leaflet demands latLons, so this is a necessary evil.
            if (feature.geometry.type == "Polygon") {
              let coordinates = feature.geometry.coordinates[0].map((lonLat) => 
              { 
                return new L.latLng({lng: lonLat[0], lat: lonLat[1] }) 
              });
              mapComponent.polygons[feature.id] = L.polygon(coordinates, polygonOptions).addTo(map);
            }
            else if (feature.geometry.type == "MultiPolygon") {
              let coordinates = feature.geometry.coordinates.map((polygon) => 
              { 
                return polygon[0].map((lonLat) => { return new L.latLng({lng: lonLat[0], lat: lonLat[1] }) });
              });
              mapComponent.polygons[feature.id] = L.polygon(coordinates, polygonOptions).addTo(map);
            }
            

          }
        }
      });
    }
  }

}

class DataMarker extends L.Marker {
  data:any;
  constructor(coords, data) {
    super(coords)
    this.data = data;
    this.setOpacity(0.5);
  }
}

class TemplatedControl extends L.Control {
  data: any;
  template: string;
  marker: any;
  allDceList: any;
  dceService: DceService;

  constructor(position: String, template: string, marker: any, allDceList : any, dceService: DceService) {
    super({position: position});
    this.template = template;
    this.data = marker.target.data;
    this.marker = marker;
    this.allDceList = allDceList;
    this.dceService = dceService;
  }


  onAddGuts(map, dataElement){
    
    document.getElementById("siteName").textContent = dataElement.SiteName ? dataElement.SiteName : "N/A";
    document.getElementById("eventName").textContent = dataElement.EventName ? dataElement.EventName : "N/A";
    document.getElementById("lat").textContent = dataElement.Latitude;
    document.getElementById("long").textContent = dataElement.Longitude;
    document.getElementById("state").textContent = dataElement.DataCollectionSiteState ? dataElement.DataCollectionSiteState : "N/A";
    document.getElementById("county").textContent = dataElement.DataCollectionSiteCounty ? dataElement.DataCollectionSiteCounty: "N/A";
    document.getElementById("date").textContent = dataElement.StartDate ? dataElement.StartDate.toString() : "";

    if (dataElement.ProtocolId) {
      var element = document.getElementById("protocol")
      this.cleanElement(element);
      this.addAnchorElement(`${environment.mrUrl}/Document/Protocol/Details/${dataElement.ProtocolId}`, dataElement.ProtocolTitle, element);    
    } else {
      document.getElementById("protocol").textContent = "N/A";
    }

    if (dataElement.StudyPlanName) {
      var element = document.getElementById("design")
      this.cleanElement(element);
      this.addAnchorElement(`${environment.mrUrl}/Resources/StudyPlanSummary/StudyPlan/${dataElement.StudyPlanId}`, dataElement.StudyPlanName, element);
    } else {
      document.getElementById("design").textContent = "N/A";
    }

    if (dataElement.ProgramName) {
      var element = document.getElementById("internalProgram")
      this.cleanElement(element);
      this.addAnchorElement(`${environment.mrUrl}/Resources/Program/Detail/${dataElement.ProgramId}`, "Overview", element);    
    } else {
      document.getElementById("internalProgram").textContent = "N/A";
    }

    if (dataElement.ProgramName && dataElement.ProgramUrl) {
      var element = document.getElementById("externalProgram")
      this.cleanElement(element);
      this.addAnchorElement(dataElement.ProgramUrl, "Origin", element);    
    } else {
      document.getElementById("externalProgram").textContent = "N/A";
    }
    
    if (dataElement.OrganizationShortName) {
      var element = document.getElementById("organization")
      this.cleanElement(element);
      this.addAnchorElement(`${environment.mrUrl}/Resources/Organization/Detail/${dataElement.OrganizationId}`, dataElement.OrganizationShortName, element);    
    } else {
      document.getElementById("organization").textContent = "N/A";
    }

    if (dataElement.DataRepositoryList) {
      var element = document.getElementById("dataRepository");
      this.cleanElement(element);
      for(var i = 0; i< dataElement.DataRepositoryList.length; i++){
        var dr = dataElement.DataRepositoryList[i];
        this.addAnchorElement(`${environment.mrUrl}/Resources/DataRepository/Detail/${dr.DataRepositoryId}`, dr.Title, element);
        if(i != (dataElement.DataRepositoryList.length - 1)){
          this.addSpanElement( ', ', element);  
        }
      }
      
         
    } else {
      document.getElementById("dataRepository").textContent = "N/A";
    }
    
    
  }

  onAdd(map) {
    var template = document.getElementById(this.template);
    var dataCollectionEventId = this.data.DataCollectionEventId;
    if(this.allDceList != null && this.allDceList.length >0){

      let dataVal = null;
      for(let i = 0; i < this.allDceList.length; i++){
        let full = this.allDceList[i];
        if(full.DataCollectionEventId === dataCollectionEventId){
          dataVal = full;
        }
      }

      this.onAddGuts(map, dataVal);
    }
    else{

      this.dceService.getDceNonGraphQLSingle(dataCollectionEventId).subscribe(result => {
        this.onAddGuts(map, result);
       })
    }
    
    let control = document.importNode(template, true).firstElementChild;
    return control;
  }

  private addAnchorElement(url: string, text:string, element: HTMLElement) {
    var aTag = document.createElement('a');
    aTag.setAttribute('href', url);
    aTag.setAttribute('class', "identify-link");
    aTag.setAttribute('target', "_blank");
    aTag.innerText = text;
    element.appendChild(aTag);
  }

  private addSpanElement(text: string, element: HTMLElement){
    var aTag = document.createElement('span');
    aTag.innerText = text;
    element.appendChild(aTag);
  }

  cleanElement(element:HTMLElement)
  {
    var child = element.lastElementChild;  
    while (child) { 
      element.removeChild(child); 
      child = element.lastElementChild; 
    }
    element.textContent = "";
  }

  onRemove() {

  }
}
