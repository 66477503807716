import { Component, OnInit, OnDestroy, OnChanges, ViewChildren, ChangeDetectionStrategy, Input } from '@angular/core';
import * as shpwrite from '@mapbox/shp-write';

@Component({
    selector: 'me-dce-list',
    templateUrl: './dce-list.component.html',
    styleUrls: ['./dce-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DceListComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChildren("dceTable") dceTable;
    @Input() selectedDces;
    @Input() dceList;
    @Input() allDceList;
    cols: any[];
    first: HTMLAnchorElement;

    constructor() {
    }

    ngOnInit() { 
        this.cols = [
            {field: 'SiteName', header: 'Site Name'},
            {field: 'EventName', header: 'Event Name'},
            {field: 'Latitude', header: 'Lat'},
            {field: 'Longitude', header: 'Long'},
            {field: 'StartDate', header: 'Date'},
            {field: 'DataCollectionSiteState', header: 'State'},
            {field: 'DataCollectionSiteCounty', header: 'County'},
            {field: 'Categories', header: 'Category'},
            {field: 'ProtocolTitle', header: 'Protocol'},
            {field: 'protocolUrl', header: 'Protocol Url'},
            {field: 'StudyPlanName', header: 'Study Plan'},
            {field: 'studyPlanUrl', header: 'Study Plan Url'},
            {field: 'ProgramName', header: 'Program'},
            {field: 'ProgramUrl', header: 'Program Url'},
            {field: 'OrganizationShortName', header: 'Organization'},
            {field: 'OrganizationUrl', header: 'Organization Url'},
            {field: 'DownloadUri', header: 'URL'},
            {field: 'DataProviderName', header: 'Data Provider'}
        ]
       
    }

    ngOnChanges() {
        // The paginator was not reseting to page 1 when the filters were changed, this fixes the problem
        // It is hacky, but none of the other solutions on Stackoverflow, Github, or PrimeNG actually worked; this does
        this.first = document.querySelector("a.ui-paginator-first");
        if (this.first) {
            this.first.click();
        }
       
    }
    
    ngOnDestroy() {

    }

    exportSHP() {
        // turn dces into features
        let features = [];
        this.dceList.forEach((dce, idx) => {
            let coordinates = [dce.Latitude, dce.Longitude];
            let geometry = {type: "Point", coordinates: coordinates};
            let dataLocation = dce.DownloadUri ? dce.DownloadUri : dce.DataProviderName;
            let feature = {
                type: "Feature",
                geometry: geometry,
                properties: {
                    siteName: dce.SiteName,
                    date: dce.StartDate,
                    dataLocation: dataLocation
                }
            };
            features.push(feature);
        })

        // set up shp-write options
        let options = {
            folder: "meshapes",
            types: {
                point: "mepoints"
            },
            compression: "STORE", // todo: verify this downloads something reasonable
            outputType: "blob" // todo: verify
        } as shpwrite.DownloadOptions & shpwrite.ZipOptions;

        // download shp files
        shpwrite.download({
            type: "FeatureCollection",
            features: features
        }, options);
    }

    uniqueFilter(value, index, self) {
        return self.indexOf(value) === index;
    }
}
