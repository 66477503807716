<header id="header" class="hideable">
  <div class="header-top">
    <div class="container">
      <nav class="header-nav">
      </nav>
    </div>
  </div>
  <div class="header-bottom">
    <div class="container">
      <div class="logo-holder">
      <!-- page logo -->
        <div class="logo">
          <a href="{{ mrUrl }}"><img src="assets/img/logo.png" height="50" width="155" alt="Monitoring Resources"></a>
        </div>
        <div class="text">
          <strong class="main">Monitoring Resources</strong>
          <span class="sub">sponsored by: <a href="http://www.pnamp.org">pacific northwest aquatic monitoring partnership</a></span>
        </div>
      </div>
      <nav id="nav">
        <a href="javascript:void(0);" class="nav-opener">
          <span><span class="hide-text">nav</span></span>
        </a>
        <ul id="MainMenu">
          <li><a href="{{ mrUrl }}">Home</a></li>
        </ul>
      </nav>
    </div>
  </div>
</header>