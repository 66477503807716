import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRouteService } from '../api-route/api-route.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BusyService } from '../busy/busy.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private busyService: BusyService, private apiRoute: ApiRouteService, private http: HttpClient) {

    }

    getFromApi(relativeRoute: string): Observable<any> {
        return this.getFromApiWithParams(relativeRoute, null);
    }

    getFromApiWithParams(relativeRoute: string, params: HttpParams): Observable<any> {

        this.busyService.setBusy(true);

        if (relativeRoute.startsWith('/')) {
            relativeRoute = relativeRoute.substring(1, relativeRoute.length);
        }

        const baseRoute = this.apiRoute.getRoute();
        const route = `${baseRoute}/${relativeRoute}`;
        const result = this.http.get(route, {params: params})
            .pipe(
                map((response: any) => {
                    return this.handleResponse(response);
                }),
                catchError((error: any) => {
                    return this.handleError(error);
                })
            );
        return result;
    }

    handleResponse(response: any): Observable<any> {
        this.busyService.setBusy(false);
        return response;
    }

    handleError(errorResponse: any): Observable<any> {
        this.busyService.setBusy(false);
        return errorResponse;
    }
}
