<div style="position: fixed; height:100%; width: 100%;">
    <div style="height: 100%;"
        leaflet 
        [leafletOptions]="options"
        [leafletMarkerCluster]="markerClusterData"
        (leafletMapReady)="onMapReady($event)"
        id="map">
    </div>
</div>

<div id="identify-box-template">
    <div class="leaflet-bar leaflet-control" id="identify-box">
        <h5>Data Collection Event</h5>
        <div id="selectedDceInfo">
            <strong>Site Name: </strong><label id="siteName"></label><br />
            <strong>Event Name: </strong><label id="eventName"></label><br />
            <strong>Lat: </strong><label id="lat"></label><br />
            <strong>Long: </strong><label id="long"></label><br />
            <strong>Date: </strong><label id="date"></label><br />
            <strong>State: </strong><label id="state"></label><br />
            <strong>County: </strong><label id="county"></label><br />
            <strong>Study Plan Summary: </strong><label id="design"></label><br />
            <strong>Protocol: </strong><label id="protocol"></label><br />
            <strong>Program: </strong><br />
              <ul>
                    <li style="display: list-item; list-style-type: disc; margin-left: 2em;"><span id="internalProgram" ></span></li>
                    <li style="display: list-item; list-style-type: disc; margin-left: 2em;"><span id="externalProgram"></span></li>
              </ul>
            <strong>Organization: </strong><label id="organization"></label><br />
            <strong>Data Repository: </strong><label id="dataRepository"></label><br />
        </div>
        
    </div>
</div>