<div class="dce-list-container">
    <p-panel class="panel-data-export" id="data-grid" header="Data Export Options ({{dceList.length}} Events)" [toggleable]="'true'" [collapsed]="'true'">
        <div class="dce-list-table" *ngIf="dceList.length > 0 && allDceList.length > 0" >
            <p-table [ngClass]="sites-grid-table" #dceTable [columns]="cols" [autoLayout]="true" [value]="dceList" [paginator]="true" [rows]="10">
                <ng-template pTemplate="caption" class="container-for-buttons">
                    <div class="export-buttons-container">
                        <button class="export-buttons" type="button" pButton label="Export CSV" (click)="dceTable.exportCSV()" style="float:right;"></button>
                        <button class="export-buttons" type="button" pButton label="Export SHP" (click)="exportSHP()" style="float:right; margin-right: 10px"></button>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr style="font-size: 8px !important; border: 2px solid #7b797946 !important;">
                        <th>
                            Site Name
                        </th>
                        <th >
                            Event Name
                        </th>
                        <th >
                            Lat
                        </th>
                        <th >
                            Long
                        </th>
                        <th >
                            Date
                        </th>
                        <th >
                            State
                        </th>
                        <th >
                            County
                        </th>
                        <th >
                            Category
                        </th>
                        <th >
                            Protocol
                        </th>
                        <th >
                            Study Plan
                        </th>
                        <th >
                            Program
                        </th>
                        <th >
                            Organization
                        </th>
                        <th >
                            Data Repository
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-sdce>
                    <tr style="font-size: 8px !important">
                        <td>
                            {{ sdce.SiteName }}
                        </td>
                        <td>
                            {{ sdce.EventName }}
                        </td>
                        <td>
                            {{ sdce.Latitude }}
                        </td>
                        <td>
                            {{ sdce.Longitude }}
                        </td>
                        <td>
                            {{ sdce.StartDate }}
                        </td>
                        <td>
                            {{ sdce.DataCollectionSiteState }}
                        </td>
                        <td>
                            {{ sdce.DataCollectionSiteCounty }}
                        </td>
                        <td>
                            {{ sdce.Categories }}
                        </td>
                        <td>
                            <a *ngIf="sdce.ProtocolTitle" [href]="sdce.ProtocolUrl" target="_blank">{{ sdce.ProtocolTitle }}</a>
                        </td>
                        <td>
                            <a *ngIf="sdce.StudyPlanName" [href]="sdce.StudyPlanUrl" target="_blank">{{ sdce.StudyPlanName }}</a>
                        </td>
                        <td>
                            <a *ngIf="sdce.ProgramName" [href]="sdce.ProgramUrl" target="_blank">{{ sdce.ProgramName }}</a>                        
                        </td>
                        <td>
                            <a *ngIf="sdce.OrganizationShortName" [href]="sdce.OrganizationUrl" target="_blank">{{ sdce.OrganizationShortName }}</a>                        
                        </td>
                        <td>
                            <a *ngFor="let dr of sdce.DataRepositoryList; let isLast=last" [href]="dr.Url"target="_blank">{{ dr.Title }}{{isLast ? '' : ', '}}</a>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-panel>
</div>