import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { Observable } from 'rxjs';
import { DataCollectionEventDto } from '../models/data-collection-event-dto';
import { CategoryDto } from '../models/category-dto';
import { ProgramDto } from '../models/program-dto';
import { ProtocolDto } from '../models/protocol-dto';
import { MethodDto } from '../models/method-dto';
import { DataCollectionEventLightDto } from '../models/method-dto';
import { OrganizationDto } from '../models/organization-dto';
import { DCE, ResponseDCE } from '../interfaces/dce';
import { DCEFilters } from '../interfaces/dce-filters';

@Injectable({
    providedIn: 'root'
})
export class ExplorerService {

    constructor(private apiService: ApiService) { }

    private getGQLQueryStringOnly(filters: DCEFilters): string{
        let filterString = "";
        if (filters && Object.keys(filters).length > 0){
            filterString = `${Object.keys(filters).map(key => {
                let val = "";
                if (filters[key] instanceof Date){
                    val = `"${formatDate(filters[key],'shortDate', 'en-US')}"`;
                }
                else
                    val = (typeof filters[key] === "number") ? `${filters[key]}` : JSON.stringify(filters[key]); // We stringify to ensure quotes are properly escaped
                return `${key}: ${val}`;
            }).join(", ")}`;
        }

        return `{${filterString}}`;
    }

    private getGQLQueryString(filters: DCEFilters): string{
        let filterString = "";
        if (filters && Object.keys(filters).length > 0){
            filterString = `(${Object.keys(filters).map(key => {
                let val = "";
                if (filters[key] instanceof Date){
                    val = `"${formatDate(filters[key],'shortDate', 'en-US')}"`;
                }
                else
                    val = (typeof filters[key] === "number") ? `${filters[key]}` : JSON.stringify(filters[key]); // We stringify to ensure quotes are properly escaped
                return `${key}: ${val}`;
            }).join(", ")})`;
        }

        return `{
            dces ${filterString}
            {
                dataCollectionEventId 
                eventName 
                siteName
                organizationId 
                dataCollectionSiteId
                startDate
                downloadUri
                protocol {
                    protocolId
                    protocolTitle
                }
                sampleDesign {
                    sampleDesignId
                    studyPlan {
                        studyPlanId
                        name
                    }
                }
                program {
                    programId
                    name
                    url
                }
                organization {
                    shortName
                }
                dataCollectionEventClassifications {
                    category
                    subcategory
                }
                dataCollectionSite {
                    dataCollectionSiteId 
                    state
                    county: county
                    geometry
                }
                dataProvider {
                    name
                }
                dataRepositories {
                    title
                    dataRepositoryId
                    url
                }
            } 
        }`;
    }

    // Domain Data methods
    getStates(): Observable<string[]>{
        let route = `domain-data/states`;
        return this.apiService.getFromApi(route);
    }

    getCounties(state: string): Observable<string[]>{
        let route = `domain-data/states/${state}/counties`;
        return this.apiService.getFromApi(route);
    }

    getLandOwnershipTypes(): Observable<string[]>{
        let route = `domain-data/land-ownership-types`;
        return this.apiService.getFromApi(route);
    }
    
    getCategoriesAndSubCategories(): Observable<CategoryDto[]>{
        let route = `domain-data/categories-and-sub-categories`;
        return this.apiService.getFromApi(route);
    }
    
    getProtocols(): Observable<ProtocolDto[]>{
        let route = `domain-data/protocols`;
        return this.apiService.getFromApi(route);
    }
    
    getMethods(): Observable<MethodDto[]>{
        let route = `domain-data/methods`;
        return this.apiService.getFromApi(route);
    }
    
    getPrograms(): Observable<ProgramDto[]>{
        let route = `domain-data/programs`;
        return this.apiService.getFromApi(route);
    }
    
    getOrganizations(): Observable<OrganizationDto[]>{
        let route = `domain-data/organizations`;
        return this.apiService.getFromApi(route);
    }
    // end Domain Data Methods

    // DCE methods
    getDCEs(): Observable<DataCollectionEventDto[]> {
        let route = 'exchange/dce';
        return this.apiService.getFromApi(route);
    }

    getDCE(id: string): Observable<DataCollectionEventDto> {
        let route = `exchange/dce/${id}`;
        return this.apiService.getFromApi(route);
    }
    // end DCE methods

    //graphql methods
    getDCEsGraphQL(): Observable<ResponseDCE>{
        let route = 'graphql';
        let params = new HttpParams();
        let query = this.getGQLQueryString(null);
        params = params.append('query', query);
        return this.apiService.getFromApiWithParams(route, params);
    }

    getDCEsGraphQLWithFilter(filters: DCEFilters): Observable<any>{
        let query = this.getGQLQueryString(filters);
        let route = 'graphql';
        let params = new HttpParams();
        params = params.append('query', query);
        return this.apiService.getFromApiWithParams(route, params);
    }

    getDCEsWithFilter(filters: DCEFilters): Observable<any>{
        let query = this.getGQLQueryStringOnly(filters);
        let route = `domain-data/dataCollectionEvents`;
        let params = new HttpParams();
        params = params.append('query', query);
        return this.apiService.getFromApiWithParams(route, params);
    }

    getDCEByDataCollectionEventId(dataCollectionEventId: number): Observable<any>{
        let route = `domain-data/dataCollectionEventSingle`;
        let params = new HttpParams();
        params = params.append('dataCollectionEventId', dataCollectionEventId.toString());
        return this.apiService.getFromApiWithParams(route, params);
    }

    getDCEsWithFilterFast(): Observable<any>{
        let route = `domain-data/dataCollectionEventsLight`;
        return this.apiService.getFromApi(route);
    }

    getDCEsWithNoFilter(): Observable<ResponseDCE>{
        let query = this.getGQLQueryStringOnly(null);
        let route = `domain-data/dataCollectionEvents`;
        let params = new HttpParams();
        params = params.append('query', query);
        return this.apiService.getFromApiWithParams(route, params);
    }
}
