import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ActivationEnd } from '@angular/router';

@Component({
    selector: 'main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {

    activatedRoute: ActivatedRoute
    constructor(private router: Router, private route: ActivatedRoute) {
        this.activatedRoute = route;
    }

    ngOnInit() {
        // this.router.navigate(['/main']);
        //this.router.navigate(['/main', { outlets: { inner: ['dashboards'] } }]);
    }

}
