import { Map, tileLayer, latLng, layerGroup } from 'leaflet';
import { environment } from 'src/environments/environment';
declare const L: any;

export class DetailedLayersSelector extends L.Control {
    private _layers: any[];
    private _groupList: any[];
    private _container: any;
    private _domGroups: any[];
  
    options: any;
  
    constructor(baseLayers: Record<string, any>, layerGroups: Record<string,Record<string,any>>, options: any){
        super();
        this.options = options || {
            position: 'topright',
            attributionControl: false
        };
        L.Util.setOptions(this, this.options);
  
        this._layers = [];
        this._groupList = [];
        this._domGroups = [];

        for (let baseLayerName of Object.keys(baseLayers)) {
            this._addLayer(baseLayers[baseLayerName], baseLayerName, false);
        }

        for (let layerGroupName of Object.keys(layerGroups)) {
            let layerGroup = layerGroups[layerGroupName];
            for (let layerName of Object.keys(layerGroup)) {
                let layer = layerGroup[layerName];
                this._addLayer(layer, layerName, layerGroupName, true)
            }
        }
    }
  
    onAdd(map: Map) {        
        this._initLayout();
        this._update();

        map.on('layeradd', this._onLayerChange, this)
            .on('layerremove', this._onLayerChange, this);
        
        return this._container;
    }
  
    onRemove(map: Map) {
        map.off('layeradd', this._onLayerChange, this)
            .off('layerremove', this._onLayerChange, this);
    }
  
    private _addLayer(layer, name?, group?, overlay?){
  
        let _layer = {
            layer: layer,
            name: name,
            overlay: overlay,
            group: null,
        };
  
        this._layers.push(_layer);
  
        group = group || '';
        let groupId = this._groupList.findIndex(g => g == group);
  
        if (groupId === -1) {
            groupId = this._groupList.push(group) - 1;
        }
  
        _layer.group = {
            name: group,
            id: groupId,
        };
  
    }

    private _initLayout() {
        let className = 'leaflet-control-layers',
            container = this._container = L.DomUtil.create('div', className);
        let form = this._form = L.DomUtil.create('form', className + '-list');

        if (this.options.collapsed) {
            if (!L.Browser.android) {
              L.DomEvent
                  .on(container, 'mouseover', this._expandControl, this)
                  .on(container, 'mouseout', this._collapseControl, this);
            }
            var link = this._layersLink = L.DomUtil.create('a', className + '-toggle', container);
            link.href = '#';
            link.title = 'Layers';
      
            if (L.Browser.touch) {
              L.DomEvent
                  .on(link, 'click', L.DomEvent.stop)
                  .on(link, 'click', this._expandControl, this);
            } else {
              L.DomEvent.on(link, 'focus', this._expandControl, this);
            }
      
            this._map.on('click', this._collapseControl, this);
          } else {
            this._expandControl();
          }

        this._baseLayersList = L.DomUtil.create('div', className + '-base', form);
        this._separator = L.DomUtil.create('div', className + '-separator', form);
        this._overlaysList = L.DomUtil.create('div', className + '-overlays', form);

        let tooltiptext = document.createElement('span');
        tooltiptext.innerText = '';
        tooltiptext.className = 'majorTooltiptext';

        this._majorTooltiptext = tooltiptext;
        container.appendChild(tooltiptext);

        container.appendChild(form);
    }

    private _update() {
        if (!this._container) {
            return;
        }

        this._baseLayersList.innerHTML = '';
        this._overlaysList.innerHTML = '';
        this._domGroups.length = 0;

        let baseLayersPresent = false,
            overlaysPresent = false;

        let expandCollapseAll = document.createElement('div'),
            expandAll = document.createElement('a'),
            collapseAll = document.createElement('a'),
            seperator = document.createElement('span');

        expandAll.className = 'expand-all';
        expandAll.href = 'javascript:void(0)';
        expandAll.innerText = 'expand all';
        seperator.innerText = ' | ';
        collapseAll.className = 'collapse-all';
        collapseAll.href = 'javascript:void(0)';
        collapseAll.innerText = 'collapse all';

        L.DomEvent.on(expandAll, 'click', this._expandAll, this._overlaysList);
        L.DomEvent.on(collapseAll, 'click', this._collapseAll, this._overlaysList);

        expandCollapseAll.appendChild(expandAll);
        expandCollapseAll.appendChild(seperator);
        expandCollapseAll.appendChild(collapseAll);
 
        this._overlaysList.appendChild(expandCollapseAll);

        let metadataInfoText = document.createElement('div');
        metadataInfoText.innerHTML = 'Click <img src="assets/img/metadata.png" style="vertical-align: top;" /> next to the layer for metadata.'

        this._overlaysList.appendChild(metadataInfoText);

        this._layers.forEach((layer) => {
            this._addItem(layer, this._majorTooltiptext);
            overlaysPresent = overlaysPresent || layer.overlay;
            baseLayersPresent = baseLayersPresent || !layer.overlay;
        });

        this._separator.style.display = overlaysPresent && baseLayersPresent ? '' : 'none';

    }
    
    private _onLayerChange(event) {
        let obj = this._getLayer(L.Util.stamp(event.layer)),
          type;
    
        if (!obj) {
          return;
        }
    
        if (!this._handlingClick) {
          this._update();
        }
    
        if (obj.overlay) {
          type = event.type === 'layeradd' ? 'overlayadd' : 'overlayremove';
        } else {
          type = event.type === 'layeradd' ? 'baselayerchange' : null;
        }
    
        if (type) {
          this._map.fire(type, obj);
        }
    }

    private _createRadioElement(name, checked) {
        let radio = document.createElement('input');
        radio.type = 'radio';
        radio.className = 'leaflet-control-layers-selector';
        radio.name = name;

        if (checked) {
            radio.setAttribute("checked", "checked");
        }

        let radioFragment = document.createElement('div');
        radioFragment.append(radio);

        return radioFragment.firstChild;
    }

    private _addItem(item, majorTooltiptext) {
        var label = document.createElement('label'),
            input,
            checked = this._map.hasLayer(item.layer),
            container,
            groupRadioName;

        label.classList.add('leaflet-layer-selector');
  
        if (item.overlay) {
            if (item.group.exclusive) {
                groupRadioName = 'leaflet-exclusive-group-layer-' + item.group.id;
                input = this._createRadioElement(groupRadioName, checked);
            } else {
                input = document.createElement('input');
                input.type = 'checkbox';
                input.className = 'leaflet-control-layers-selector';
                input.defaultChecked = checked;
            }
        } else {
            input = this._createRadioElement('leaflet-base-layers', checked);
        }

        input.layerId = L.Util.stamp(item.layer);
        input.groupID = item.group.id;
        L.DomEvent.on(input, 'click', this._onInputClick, this);
    
        var name = document.createElement('span');
        name.innerText = ' ' + item.name;
    
        label.appendChild(input);
        label.appendChild(name);
        
        if (item.layer.options.hoverInfo) {
            L.DomEvent.on(label, 'mouseover', this.doSomethingWithTooltip, {label: label, majorTooltiptext: majorTooltiptext});
            L.DomEvent.on(label, 'mouseout', this.hideTheTooltip, {label: label, majorTooltiptext: majorTooltiptext});

            label.classList.add('layer-selector-tooltip');

            let tooltiptext = document.createElement('span');
            tooltiptext.innerText = item.layer.options.hoverInfo;
            tooltiptext.className = 'tooltiptext';


            label.appendChild(tooltiptext);
        }

        if (item.layer.options.metadataUrl && item.layer.options.metadataUrl.length > 0) {
            let metadataLink = document.createElement('a');
            metadataLink.className = 'metadata-link';
            metadataLink.href = item.layer.options.metadataUrl;
            metadataLink.target = '_blank';

            label.appendChild(metadataLink);
        }

        if (item.layer.options.getWMSLegend){
            let legendImage = document.createElement('img');
            legendImage.src = `${environment.wmsMapServerUrl}?SERVICE=WMS&REQUEST=GetLegendGraphic&VERSION=1.3.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=MonitoringExplorer:LandOwnership`;
            legendImage.className = 'legend-image';
            label.appendChild(legendImage);
        }
  
        if (item.overlay) {
            container = this._overlaysList;
    
            var groupContainer = this._domGroups[item.group.id];
    
            // Create the group container if it doesn't exist
            if (!groupContainer) {
                groupContainer = document.createElement('div');
                groupContainer.className = 'leaflet-control-layers-group';
                groupContainer.id = 'leaflet-control-layers-group-' + item.group.id;
        
                var groupLabel = document.createElement('label');
                groupLabel.className = 'leaflet-control-layers-group-label';

        
                if (item.group.name !== '' && !item.group.exclusive) {
                    // ------ add a group checkbox with an _onInputClickGroup function
                    if (this.options.groupCheckboxes) {
                        var groupInput = document.createElement('input');
                        groupInput.type = 'checkbox';
                        groupInput.className = 'leaflet-control-layers-group-selector';
                        L.DomEvent.on(groupInput, 'click', this._onGroupInputClick, groupInput);
                        groupLabel.appendChild(groupInput);
                    }
                }
        
                
                var collapsibleContents = document.createElement('div');
                collapsibleContents.className = 'collapsible';

                var groupName = document.createElement('span');
                groupName.className = 'leaflet-control-layers-group-name';
                groupName.innerText = item.group.name;
                groupLabel.appendChild(groupName);
                
                var groupCollapser = document.createElement('span');
                groupCollapser.className = 'collapser';
                L.DomEvent.on(groupCollapser, 'click', this._onGroupCollapse, { collapser: groupCollapser, childList: collapsibleContents });
        
                groupLabel.appendChild(groupCollapser);
                groupContainer.appendChild(groupLabel);

                groupContainer.appendChild(collapsibleContents);
                container.appendChild(groupContainer);

                groupContainer = collapsibleContents;
        
                this._domGroups[item.group.id] = groupContainer;
            }
    
            container = groupContainer;
        } else {
            container = this._baseLayersList;
        }
    
        container.appendChild(label);
    
        return label;
    }

    private _onGroupInputClick(e) {
        L.DomEvent.stopPropagation(e);
        let obj;

        let this_legend = this.legend;
        this_legend._handlingClick = true;

        var inputs = this_legend._form.getElementsByTagName('input');

        for (let input of inputs) {
            if (input.groupID === this.groupID && input.className === 'leaflet-control-layers-selector') {
                input.checked = this.checked;
                obj = this_legend._getLayer(input.layerId);
                if (input.checked && !this_legend._map.hasLayer(obj.layer)) {
                    this_legend._map.addLayer(obj.layer);
                } else if (!input.checked && this_legend._map.hasLayer(obj.layer)) {
                    this_legend._map.removeLayer(obj.layer);
                }
            }
        }

        this_legend._handlingClick = false;
    }

    private _onInputClick(e) {
        L.DomEvent.stopPropagation(e);
        let obj, inputs = this._form.getElementsByTagName('input');

        this._handlingClick = true;

        for (let input of inputs) {
            if (input.className === 'leaflet-control-layers-selector') {
                obj = this._getLayer(input.layerId);

                if (input.checked && !this._map.hasLayer(obj.layer)) {
                    this._map.addLayer(obj.layer);
                } else if (!input.checked && this._map.hasLayer(obj.layer)) {
                    this._map.removeLayer(obj.layer);
                }
            }
        }

        this._handlingClick = false;
    }

    private _onGroupCollapse(e) {
        L.DomEvent.stopPropagation(e);
        let collapser = this.collapser, childList = this.childList;
        childList.classList.toggle("expanded");
        collapser.classList.toggle("expanded");
    }

    private _expandAll(e) {
        L.DomEvent.stopPropagation(e);
        let collapsibleGroups = this.getElementsByClassName("collapsible");
        for (let group of collapsibleGroups) {
            if (!group.classList.contains("expanded")) {
                group.classList.add("expanded");
            }
        }

        let collapsers = this.getElementsByClassName("collapser");
        for (let collapser of collapsers) {
            if (!collapser.classList.contains("expanded")) {
                collapser.classList.add("expanded");
            }
        }
    }

    private hideTheTooltip(e){
        let majorToolTip = this.majorTooltiptext;
        majorToolTip.innerHTML = '';
        majorToolTip.classList.remove('majorTooltiptextShow');
    }

    private doSomethingWithTooltip(e){
        let label = this.label;
        let majorToolTip = this.majorTooltiptext;
        let childNodes = label.childNodes;
        console.log(childNodes[2].innerHTML);
        console.log(majorToolTip);
        majorToolTip.innerHTML = childNodes[2].innerHTML;
        majorToolTip.classList.add('majorTooltiptextShow');
    }

    private _collapseAll(e) {
        L.DomEvent.stopPropagation(e);
        let collapsibleGroups = this.getElementsByClassName("collapsible");
        for (let group of collapsibleGroups) {
            if (group.classList.contains("expanded")) {
                group.classList.remove("expanded");
            }
        }

        let collapsers = this.getElementsByClassName("collapser");
        for (let collapser of collapsers) {
            if (collapser.classList.contains("expanded")) {
                collapser.classList.remove("expanded");
            }
        }
    }

    private _getLayer(id) {
        return this._layers.find(l => l && L.stamp(l.layer) === id);
    }

    private _expandControl() {
        L.DomUtil.addClass(this._container, 'leaflet-control-layers-expanded');
        // permits to have a scrollbar if overlays heighter than the map.
        let acceptableHeight = this._map._size.y - (this._container.offsetTop * 4);
        if (acceptableHeight < this._form.clientHeight) {
          L.DomUtil.addClass(this._form, 'leaflet-control-layers-scrollbar');
          this._form.style.height = acceptableHeight + 'px';
        }
    }

    private _collapseControl(){
        this._container.className = this._container.className.replace(' leaflet-control-layers-expanded', '');
    }
}

export class DetailedLayer extends L.TileLayer.WMS {
    hoverInfo: string;
    metadataUrl: string;
    getWMSLegend: boolean;
  
    constructor(baseUrl: string, options?: any){
      super(baseUrl, options);
      this.hoverInfo = options.hoverInfo;
      this.metadataUrl = options.metadataUrl;
      this.getWMSLegend = options.getWMSLegend;
    }
  
  }