<div class="map-page-container" style="height:100%; width: 100%;">
        <p-panel header="{{ filterLabel }}" [toggleable]="'true'" [collapsed]="'true'">
            <div class="p-grid filter">
                <div class="p-col-3">
                    <strong class="filterHeader">HOW</strong>
                    <div class="dropdowns-container">
                        <p-dropdown autoWidth="false" [style]="{'width':'45%', 'margin-bottom': '5px', 'margin-right': '5px'}" placeholder="[Select Protocol]" [options]="protocols" [(ngModel)]="selectedProtocol"></p-dropdown>
                        <p-dropdown autoWidth="false" [style]="{'width':'45%', 'margin-bottom': '5px', 'margin-right': '5px'}" placeholder="[Select Method]" [options]="methods" [(ngModel)]="selectedMethod"></p-dropdown> 
                        <p-dropdown autoWidth="false" [style]="{'width':'45%', 'margin-bottom': '5px', 'margin-right': '5px'}" placeholder="[Select Category]" [options]="categories" [(ngModel)]="selectedCategory" (onChange)="changeCategory($event)"></p-dropdown>
                        <p-dropdown autoWidth="false" [style]="{'width':'45%', 'margin-bottom': '5px', 'margin-right': '5px'}" placeholder="[Select Sub-Category]"[options]="subCategories" [(ngModel)]="selectedSubCategory"></p-dropdown>
                    </div>
                </div>
                <div class="p-col-3">
                    <strong class="filterHeader">WHERE</strong>
                    <p-dropdown autoWidth="false" [style]="{'width':'45%', 'margin-bottom': '5px', 'margin-right': '5px'}" placeholder="[Select State]" [styleClass]="'filter'" [options]="states" [(ngModel)]="selectedState" (onChange)="changeState($event)"></p-dropdown>
                    <p-dropdown autoWidth="false" [style]="{'width':'45%', 'margin-bottom': '5px', 'margin-right': '5px'}" placeholder="[Select County]" [options]="counties" [(ngModel)]="selectedCounty"></p-dropdown>
                    <p-dropdown autoWidth="false" [style]="{'width':'45%', 'margin-bottom': '5px', 'margin-right': '5px'}" placeholder="[Land Owner Type]" [options]="landOwnershipTypes" [(ngModel)]="selectedLandOwnershipType"></p-dropdown>
                </div>
                <div class="p-col-3">
                    <strong class="filterHeader">WHEN</strong>
                    <p-calendar autoWidth="false" [style]="{'width':'45%', 'margin-bottom': '5px', 'margin-right': '5px'}" [(ngModel)]="selectedStartDate" [showIcon]="true" [placeholder]="'Start Date'" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"></p-calendar>
                    <p-calendar autoWidth="false" [style]="{'width':'45%', 'margin-bottom': '5px', 'margin-right': '5px'}" [(ngModel)]="selectedEndDate" [showIcon]="true" [placeholder]="'End Date'" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"></p-calendar>
                </div>
                <div class="p-col-3">
                    <strong class="filterHeader">WHO</strong>
                    <p-dropdown autoWidth="false" [style]="{'width':'45%', 'margin-bottom': '5px', 'margin-right': '5px'}" placeholder="[Select Program]" [options]="programs" [(ngModel)]="selectedProgram"></p-dropdown>
                    <p-dropdown autoWidth="false" [style]="{'width':'45%', 'margin-bottom': '5px', 'margin-right': '5px'}" placeholder="[Select Organization]" [options]="organizations" [(ngModel)]="selectedOrganization"></p-dropdown>
                </div>
                <div class="p-col-3">
                    <p-button class="map-filter-buttons" label="Apply" (onClick)="applyFilters()"></p-button>
                    <span>&nbsp;</span>
                    <p-button class="map-filter-buttons" label="Clear" (onClick)="clearFilters($event)"></p-button>
                </div>
                <div class="p-col-9">
                    Draw and filter on one or more bounding boxes by pressing this icon: <div class="draw-marker bounding-box"></div>. 
                    You may edit and delete the box(es) with the <div class="draw-marker edit"></div> and <div class="draw-marker remove"></div> icons.
                </div>
            </div>
        </p-panel>
    <div>
        <me-map-view [dceList]="dceList" [allDceList]="allDceList" [doNotRefreshMap]="doNotRefreshMap"></me-map-view>
    </div>
    <div style="position: fixed; bottom:0%; width: 100%" *ngIf="dceList.length > 0 && allDceList.length > 0">
        <me-dce-list [dceList]="dceList" [allDceList]="allDceList"></me-dce-list>
    </div>
</div>