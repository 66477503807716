import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from './main/pages';
import { MapPageComponent } from './main/pages/map-page/map-page.component';
import { NotFoundComponent, UnauthenticatedComponent, SubscriptionInsufficientComponent } from './shared/pages';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  {
    path: 'main',
    component: MainPageComponent,
    children: [      
      { path: '', component: MapPageComponent, outlet: 'inner' },
    ]
  },
  {
    path: 'subscription-insufficient',
    component: SubscriptionInsufficientComponent
  },
  {
    path: 'unauthenticated',
    component: UnauthenticatedComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
