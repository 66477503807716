import { Component, OnInit, HostListener } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'me-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})

export class HeaderNavComponent implements OnInit {
  readonly SIZE_MD = 767;
  windowWidth: number;

  navItems: MenuItem[] = [];
  activeItem: MenuItem;
  mrUrl: string;
  
  @HostListener('window:resize', ['$event'])
  resize(ev?: Event) {
      this.windowWidth = window.innerWidth;
  }
  
  constructor( ) {
    
  }
  
  ngOnInit() {    
    this.mrUrl = environment.mrUrl;

    this.resize(); 
  }
}
