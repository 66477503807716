import { Component, OnInit, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { DceService} from 'src/app/main/services/dce/dce.service';
import { ExplorerService } from 'src/app/main/services/explorer.service';
import { SelectItem } from 'primeng/api';
import { CategoryDto } from '../../models/category-dto';
import { ProgramDto } from '../../models/program-dto';
import { OrganizationDto } from '../../models/organization-dto';
import { ProtocolDto } from '../../models/protocol-dto';
import { MethodDto } from '../../models/method-dto';
import { DCEFilters } from 'src/app/main/interfaces/dce-filters';
import { MapViewComponent } from 'src/app/main/components/map-view/map-view.component';
import { forkJoin } from 'rxjs';
declare const L: any;
import { LatLngBounds } from 'leaflet';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'me-map-page',
    templateUrl: './map-page.component.html',
    styleUrls: ['./map-page.component.scss'],    
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPageComponent implements OnInit, OnDestroy {
    @ViewChild(MapViewComponent) mapViewComponent:MapViewComponent;
    states: SelectItem[];
    counties: SelectItem[];
    landOwnershipTypes: SelectItem[];
    organizations: SelectItem[];
    categories: SelectItem[];
    subCategories: SelectItem[];
    protocols: SelectItem[];
    methods: SelectItem[];
    programs: SelectItem[];

    selectedState: string;
    selectedCounty: string;
    selectedLandOwnershipType: string;
    selectedOrganization: OrganizationDto;
    selectedCategory: CategoryDto;
    selectedSubCategory: string;
    selectedProtocol: ProtocolDto;
    selectedMethod: MethodDto;
    selectedProgram: ProgramDto;
    selectedStartDate: Date;
    selectedEndDate: Date;

    boundingBoxes: LatLngBounds[] = [];
    isLoaded: boolean;
    filters: DCEFilters;
    dceList = [];
    allDceList = [];
    doNotRefreshMap = false;

    minYear = 2000;
    maxYear = (new Date()).getFullYear() + 5;
    yearRange = `${this.minYear}:${this.maxYear}`;

    filterLabel = "Filters";

  
    constructor(private dceService: DceService,
                private explorerService: ExplorerService,
                private cdr: ChangeDetectorRef) {
                 }

    ngOnInit() {

         this.dceService.getDcesNonGraphQLFast().subscribe(result => {
          this.dceList = [];
          result.forEach((dce) => { this.dceList.push(dce); });
          this.doNotRefreshMap = false;
          this.cdr.markForCheck();
          this.initializeFilters();
          this.getAllDceList(true);
         })
    }

    private initializeFilters(){
      let stateRequest = this.explorerService.getStates();
      let categoryRequest = this.explorerService.getCategoriesAndSubCategories();
      let programRequest = this.explorerService.getPrograms();
      let organizationRequest = this.explorerService.getOrganizations();
      let landOwnershipRequest = this.explorerService.getLandOwnershipTypes();
      let protocolRequest = this.explorerService.getProtocols();
      let methodRequest = this.explorerService.getMethods();

      forkJoin(stateRequest, categoryRequest, programRequest, organizationRequest, landOwnershipRequest, protocolRequest, methodRequest)
        .subscribe(([stateResults, categoryResults, programResults, organizationResults, landOwnershipResults, protocolResults, methodResults]) => {
        // Populate states
        this.states = [{ label: '[Select State]', value: null}];

        stateResults = (stateResults || []).sort((p, p2) => {
          return (p || '').localeCompare(p2 || '');
        });
        stateResults.forEach(state => {
          this.states.push({ label: state, value: state });
        });
        // Counties not populated until a state is selected
        this.counties = [{ label: '[Select County]', value: null}];
        // Populate categories
        this.categories = [{ label: '[Select Category]', value: null}];
        categoryResults = (categoryResults || []).sort((p, p2) => {
          return (p.Name || '').localeCompare(p2.Name || '');
        });
        categoryResults.forEach(category => {
          this.categories.push({ label: category.Name, value: category });
        });
        // Subcategories not populated until a category is selected 
        this.subCategories = [{ label: '[Select Sub-Category]', value: null}];
        // Populate programs
        this.programs = [{ label: '[Select Program]', value: null}];
        programResults = (programResults || []).sort((p, p2) => {
          return (p.Name || '').localeCompare(p2.Name || '');
        });
        programResults.forEach(program => {
          this.programs.push({ label: program.Name, value: program });
        });
        // Populate organizations
        this.organizations = [{ label: '[Select Organization]', value: null}];
        
        organizationResults = (organizationResults || []).sort((p, p2) => {
          return (p.ShortName || '').localeCompare(p2.ShortName || '');
        });
        organizationResults.forEach(organization => {
          this.organizations.push({ label: organization.ShortName, value: organization });
        });
        // Populate land ownership types
        this.landOwnershipTypes = [{ label: '[Land Owner Type]', value: null}];
        landOwnershipResults = (landOwnershipResults || []).sort((p, p2) => {
          return (p || '').localeCompare(p2 || '');
        });
        landOwnershipResults.forEach(type => {
          this.landOwnershipTypes.push({ label: type, value: type });
        });
        // populate protocols
        this.protocols = [{ label: '[Select Protocol]', value: null}];
        
        protocolResults = (protocolResults || []).sort((p, p2) => {
          return (p.ProtocolTitle || '').localeCompare(p2.ProtocolTitle || '');
        });
        protocolResults.forEach(protocol => {
          this.protocols.push({ label: protocol.ProtocolTitle, value: protocol });
        });
        // populate methods
        this.methods = [{ label: '[Select Method]', value: null}];
        methodResults = (methodResults || []).sort((p, p2) => {
          return (p.MethodTitle || '').localeCompare(p2.MethodTitle || '');
        });
        methodResults.forEach(method => {
          this.methods.push({ label: method.MethodTitle, value: method });
        });
        this.doNotRefreshMap = false;
        this.cdr.markForCheck();
      });
    }
    
    changeState(event: any) {
      this.setCountiesForState();
    }
    changeCategory(event: any) {
      this.setSubCategoryForCategory();
    }
    
    private setCountiesForState(){
      this.counties = [{ label: '[Select County]', value: null}];
      this.explorerService.getCounties(this.selectedState).subscribe(result => {

          var counties = (result || []).sort((p, p2) => {
            return (p || '').localeCompare(p2 || '');
          });

          counties.forEach(county => {
            this.counties.push({ label: county, value: county })
          })
      });
    }
    
    private setSubCategoryForCategory(){
      this.subCategories = [{ label: '[Select Sub-Category]', value: null}];
      if (this.selectedCategory && this.selectedCategory.SubCategories) {
        var subCategories = (this.selectedCategory.SubCategories || []).sort((p, p2) => {
          return (p || '').localeCompare(p2 || '');
        });

        subCategories.forEach(subCategory => {
          this.subCategories.push({ label: subCategory, value: subCategory })
        });
      }
    }

    clearFilters(event: any){
      this.selectedState = null;
      this.selectedCounty = null;
      this.selectedLandOwnershipType = null;
      this.selectedCategory = null;
      this.selectedSubCategory = null;
      this.selectedProtocol = null;
      this.selectedMethod = null;
      this.selectedOrganization = null;
      this.selectedProgram = null;
      this.selectedStartDate = null;
      this.selectedEndDate = null;
      this.selectedProgram = null;

      this.filterLabel = `Filters`;
      this.dceList = [];
      
      this.mapViewComponent.map.eachLayer(layer => {
        if (layer instanceof L.Rectangle){
          this.mapViewComponent.map.removeLayer(layer);
        }
      });
      this.getAllDceList(false);
    }

    getAllDceList(preventMapRefresh){
      if(this.allDceList != null && this.allDceList.length > 0 ){
        this.dceList = [];
        this.allDceList.forEach((dce) => {this.dceList.push(dce)});
        this.doNotRefreshMap = preventMapRefresh;
        this.cdr.markForCheck();
      }
      else{
        this.dceService.getDcesNonGraphQL(null).subscribe(result =>{
          this.allDceList = [];
          this.dceList = [];
          result.forEach((dce) => {
            
  
            var mrUrl = environment.mrUrl;
  
            if (dce.ProtocolTitle) {
              dce.ProtocolUrl = `${mrUrl}/Document/Protocol/Details/${dce.ProtocolId}`;
            }
            if(dce.StudyPlanName) {
              dce.StudyPlanUrl = `${mrUrl}/Resources/StudyPlanSummary/StudyPlan/${dce.StudyPlanId}`
            }
            if (dce.OrganizationShortName) {
              dce.OrganizationUrl = `${mrUrl}/Resources/Organization/Detail/${dce.OrganizationId}`
            }
  
            this.allDceList.push(dce);
            this.dceList.push(dce);
        });
  
        this.doNotRefreshMap = preventMapRefresh;
        this.cdr.markForCheck();
      }
      )
    }

  };
    
    applyFilters(){
      let numFiltersApplied = 0;

      let filters = {};
      if (this.selectedState && this.selectedState.length > 0){
        filters["state"] = this.selectedState;
        numFiltersApplied++;
      }
      if (this.selectedCounty && this.selectedCounty.length > 0){
        filters["county"] = this.selectedCounty;
        numFiltersApplied++;
      }
      if (this.selectedLandOwnershipType && this.selectedLandOwnershipType.length > 0){
        filters["landownership"] = this.selectedLandOwnershipType;
        numFiltersApplied++;
      }
      if (this.selectedCategory){
        filters["category"] = this.selectedCategory.Name;
        numFiltersApplied++;
      }
      if (this.selectedSubCategory && this.selectedSubCategory.length > 0){
        filters["subcategory"] = this.selectedSubCategory;
        numFiltersApplied++;
      }
      if (this.selectedProtocol){
        filters["protocolId"] = this.selectedProtocol.ProtocolId;
        numFiltersApplied++;
      }
      if (this.selectedMethod){
        filters["methodId"] = this.selectedMethod.TemplateMethodID;
        numFiltersApplied++;
      }
      if (this.selectedOrganization){
        filters["organizationId"] = this.selectedOrganization.OrganizationID;
        numFiltersApplied++;
      }
      if (this.selectedProgram){
        filters["programId"] = this.selectedProgram.ProgramID;
        numFiltersApplied++;
      }
      if (this.selectedStartDate){
        filters["startDate"] = this.selectedStartDate;
        numFiltersApplied++;
      }
      if (this.selectedEndDate){
        filters["endDate"] = this.selectedEndDate;
        numFiltersApplied++;
      }

      let boundingBoxes: LatLngBounds[] = []
      if(this.mapViewComponent.map) {
        this.mapViewComponent.map.eachLayer(layer => {
          if (layer instanceof L.Rectangle){
            boundingBoxes.push((layer as L.Rectangle).getBounds());
          }
        });
      }
      
      if (boundingBoxes.length > 0){
        filters["boundingBoxes"] = JSON.stringify(boundingBoxes);
        numFiltersApplied++;
      }
      
      this.filterLabel = `Filters (${numFiltersApplied} applied)`;

      this.dceService.getDcesNonGraphQL(filters).subscribe(result =>{
        this.dceList = []
        result.forEach((dce) => {
          this.dceList.push(dce);

          var mrUrl = environment.mrUrl;

          if (dce.ProtocolTitle) {
            dce.ProtocolUrl = `${mrUrl}/Document/Protocol/Details/${dce.ProtocolId}`;
          }
          if(dce.StudyPlanName) {
            dce.StudyPlanUrl = `${mrUrl}/Resources/StudyPlanSummary/StudyPlan/${dce.StudyPlanId}`
          }
          if (dce.OrganizationShortName) {
            dce.OrganizationUrl = `${mrUrl}/Resources/Organization/Detail/${dce.OrganizationId}`
          }
      });
      this.doNotRefreshMap = false;
      this.cdr.markForCheck();
    });

      
      
    }    

    ngOnDestroy(): void {
        this.cdr.detach();
    }
}