import { Map } from 'leaflet'
declare const L: any;

export class FeatureIdentifyControl extends L.Control {
    template: string;
    features: any[];
    constructor(position: String, template: string, features: any[]) {
      super({position: position});
      this.features = features;
      this.template = template;
    }
  
    onAdd(map: Map) {
      var featureIdentifyBox = document.createElement('div');
      featureIdentifyBox.className = 'feature-identify-box';
  
      for (let feature of this.features) {
        let formattedProperties = this._convertPropertiesToHtml(feature.id, feature.properties);
        featureIdentifyBox.appendChild(formattedProperties);
      }
  
      let control = document.importNode(featureIdentifyBox, true);
      return control;
    }
  
    private _convertPropertiesToHtml(featureId, properties){
      var propertyHolder = document.createElement('div');
      propertyHolder.className = 'feature-section '+featureId;
      let featureTitle = document.createElement('h5');
      featureTitle.innerText = `Feature: ${featureId}`;
      propertyHolder.append(featureTitle);
  
      let keys = Object.keys(properties);
      for (let key of keys){
        let propertyName = document.createElement('strong');
        propertyName.className = 'property-name';
        propertyName.innerText = `${key}: `;
        propertyHolder.append(propertyName);
  
        let propertyValue = document.createElement('span');
        propertyValue.className = 'property-value';
        propertyValue.innerText = properties[key];
        propertyHolder.append(propertyValue);
  
        let lineBreak = document.createElement('br');
        propertyHolder.append(lineBreak);
      }
  
      return propertyHolder;
    }
  }