export const environment = {
  production: false,
  staging: true,
  dev: false,
  apiPostfix: 'api.qa',
  apiPort: '',
  cookieDomainName: '.qa.monitoringexplorer.org',

  wmsMapServerUrl: 'https://map.qa.monitoringexplorer.org/geoserver/MonitoringExplorer/wms',
  mrUrl: 'https://qa.monitoringresources.org',

  keystoneAuthConfiguration: {
    clientId: 'ClientName',
    issuer: 'https://qa.keystone.sitkatech.com/core',
    redirectUri: window.location.origin + '/',
    scope: 'openid all_claims keystone',
    sessionChecksEnabled: true,
    logoutUrl: 'https://qa.keystone.sitkatech.com/core/connect/endsession',
    postLogoutRedirectUri: ''
  }
};

