import { Injectable } from '@angular/core';
import { ExplorerService } from 'src/app/main/services/explorer.service';

@Injectable({
    providedIn: 'root'
})
export class DceService {
    dces = [];
    dceList = [];

    constructor(private explorerService: ExplorerService) { }

    getDces(filters) {
        return this.explorerService.getDCEsGraphQLWithFilter(filters);
    }

    getDcesNonGraphQL(filters) {
        var dceList = this.explorerService.getDCEsWithFilter(filters);
        return dceList;
    }

    getDcesNonGraphQLFast() {
        var dceList = this.explorerService.getDCEsWithFilterFast();
        return dceList;
    }

    getDceNonGraphQLSingle(dataCollectionEventId) {
        var dceList = this.explorerService.getDCEByDataCollectionEventId(dataCollectionEventId);
        return dceList;
    }
    
}
