import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRouteService } from '../api-route/api-route.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BusyService } from '../busy/busy.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FeatureMapService {

    constructor(private busyService: BusyService, private apiRoute: ApiRouteService, private http: HttpClient) {

    }

    // getFromFeature(relativeRoute: string): Observable<any> {
    //     return this.getFromFeatureWithParams({ relativeRoute, params: null });
    // }

    getFromFeatureWithParams(paramString: string): Observable<any> {
        this.busyService.setBusy(true);

        const route = `${environment.wmsMapServerUrl}${paramString}`;
        const result = this.http.get(route)
            .pipe(
                map((response: any) => {
                    return this.handleResponse(response);
                }),
                catchError((error: any) => {
                    return this.handleError(error);
                })
            );
        return result;
    }

    handleResponse(response: any): Observable<any> {
        this.busyService.setBusy(false);
        return response;
    }

    handleError(errorResponse: any): Observable<any> {
        this.busyService.setBusy(false);
        return errorResponse;
    }
}
